import React,{useEffect, useState, useLayoutEffect, useRef} from 'react'
import 'svgmap/dist/svgMap.min.css'
import "./style.css"
import ReactCountryFlag from "react-country-flag"
import {countries} from 'country-data';
import {getBidData} from "../services/api"
const Map = () => {

    const [tempData, setTempData] = useState()
    const [sum, setSum] = useState();

    useEffect(async () => {
        setTempData(await getBidData(process.env.REACT_APP_BID_DATA))
    }, [])
    
    useEffect(async () => {
        const interval = setInterval(async () => {
            setTempData(await getBidData(process.env.REACT_APP_BID_DATA))
        }, 15000);
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        setSum(tempData && tempData.length)
    }, [tempData])

    const [second, setSeconds] = useState(0)    
    var test = tempData && tempData[second].userCountry    
    console.log(test)

    useEffect(() => {
        const interval = setInterval(() => {
          setSeconds(second => {
              if(sum - 1 === second) {
                  return 0
              } else {
                  return second + 1
              }});
        }, 3000);
        return () => clearInterval(interval)
      }, [sum]);

    const datas = {
        [test]: {gdp: 4293, change: 10.01}
    }
    
    useLayoutEffect(() => {  
        window.test(datas)
    }, [])
    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
      };
      

    const [countryId, setCountryId] = useState();
    const prevCountryId = usePrevious(countryId);      
    useEffect(async (usePrevious) => {
        const tesst = tempData && tempData[second].userCountry    
        var loltest = document.getElementById(`svgMap-map-country-${tesst}`).getBoundingClientRect();
        document.getElementById(`svgMap-map-country-${tesst}`).classList.add('svg-active');
        document.getElementById('svgMap').style.pointerEvents = "none";
        const matrix = document.getElementsByClassName("svg-pan-zoom_viewport")[0].style.transform.replace('matrix', '').slice(1)
        const matrix1 = matrix.split(",")[0];
        const matrix2 = matrix.split(",")[1];
        const matrix3 = matrix.split(",")[2];
        const matrix4 = matrix.split(",")[3];
        const matrixY5 = matrix.split(",")[4].replace('-', '') * 3.07;
        const matrix5 = matrix.split(",")[5];
        const matTop = 40;
        console.log(matrix);
        console.log(window.innerWidth)
        if (window.innerWidth > 991) {
            document.getElementsByClassName("svg-pan-zoom_viewport")[0].style.transform = `scale(0.8)matrix(${matrix1},${matrix2},${matrix3},${matrix4}, ${matrixY5},${matrix5} `;
            document.getElementById("realtime-card").style.top = `${loltest.bottom+15}px`;
            document.getElementById("realtime-card").style.left = `${loltest.left/1.4}px`;
        } else if (window.innerWidth < 991) {
            document.getElementsByClassName("svg-pan-zoom_viewport")[0].style.transform = `scale(1.4)matrix(${matrix1},${matrix2},${matrix3},${matrix4}, -${matrixY5*2},${matTop} `;
        }
        if (window.innerWidth < 600){
            document.getElementById("svgMap").style.display = "none"
        }
        
        

        setCountryId(tesst);
        return (
            document.getElementById(`svgMap-map-country-${prevCountryId}`).classList.remove('svg-active'),
            document.getElementById(`svgMap-map-country-${tesst}`).classList.add('svg-active')
        )
    })

    return (
        <div className="rootCard">
            <div className="green-big-round-shap">

            </div>
            <div className="red-small-round-shap">

            </div>
            <div className="green-small-round-shap">

            </div>
            {tempData && tempData[second] && Object.keys(tempData && tempData[second]).length &&
            <div className="card" id="realtime-card"> 
                <div className="desk new-artic-unblock">
                        <h2>Live Activity</h2>
                        <div className="card-box">
                            <p className="card-country">{tempData && tempData[second].userCountry}</p>
                            <ReactCountryFlag className="cardCountry mobile-country" countryCode={tempData && tempData[second].userCountry} svg />
                            </div>
                    </div>
                <div className="header">
                    <div className="logo_name">
                    {/* <img className="Country_logo" src={tempData && tempData[second].publisher_country} />
                     */}
                    <img className="player-image pub-logo" src={tempData && tempData[second].publisherLogoUrl} />
                    <div className="title-wrap">
                        <div className="country-wrap">
                        <p className="india-time country-name">{countries[tempData && tempData[second].publisherCountry].name}</p>
                        <ReactCountryFlag countryCode={tempData && tempData[second].publisherCountry} svg />
                            </div>
                    <p className="india-time">{tempData && tempData[second].publisherName}</p>
                    </div>
                    </div>
                    <div className="header-price">
                        <p className="userPrice">{tempData && tempData[second].userPrice}</p>
                    </div>
                </div>
                <div className="content box-sm-cont">
                    <div className="new-artic-unblock">
                        <h2>New Article Unlocked</h2>
                    </div>
                    <div className="imageContainer">
                        <img className="player-image article-image" src={tempData && tempData[second].articleImageUrl} />
                    </div>
                    <div className="article-wrapper">
                        <div className="header sm-header">
                            <p className="india-time">{tempData && tempData[second].publisherName}</p>
                            <p className="india-color">{tempData && tempData[second].userPrice}</p>
                        </div>
                        <p className="overflow artical-title">{tempData && tempData[second].articleTitle}</p>
                        <p className="overflow atrtical-url"><a  href={tempData && tempData[second].articleUrl} target="_blank">{tempData && tempData[second].articleUrl}</a></p>
                        <hr className="hr-artical"></hr>
                        <div className="article-container">
                        <p className=" artical-detils">{tempData && tempData[second].articleDescription}</p>
                        <p className="india-color">{tempData && tempData[second].userPrice}</p>
                        </div>
                    </div>

                </div>
                {/* <div className="publisher_logo_url">
                    <img src={tempData && tempData[second].publisherLogoUrl} />
                </div> */}
            </div>
            }
            <div className="bottom-red-small-round-shap">

            </div>
            <div className="bottom-green-big-round-shap">

            </div>
        </div>
    )
}

export default Map
