import React from "react"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Map from "./components/map"

function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route component={Map} path="/" exact />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
